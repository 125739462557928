import { Button } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export const BannerBox = () => {
  return (
    <div className='bg-black py-12'>
      <ReactPlayer
        url='https://www.apple.com/105/media/us/apple-events/2023/ad6b79de-2819-4715-be4e-c9f1488ab703/anim/large_2x.mp4'
        loop
        muted
        playing
        controls={false}
        playsinline
        width={'100%'}
      ></ReactPlayer>
      <div className='text-neutral-100 text-center text-2xl'>
        <h1>
          <p>새로운 iPhone 15</p>
          <p>지금이 바꿀 기회</p>
        </h1>
      </div>
      <div className='text-center py-8'>
        <Link to='/reserve?modelName=iPhone 15'>
          <Button
            variant='contained'
            className='bg-gradient-to-r from-stone-800 via-stone-500 to-sky-900 !text-white !text-lg font-bold'
          >
            구매하러 가기
          </Button>
        </Link>
      </div>
    </div>
  );
};
