import { Dialog, DialogContent, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { numberWithCommas } from 'common/numberWithCommas';

type PhonePlanDialogProps = {
  nextTelecom: string;
  openPhonePlan: boolean;
  onClickOpenPhonePlan(): void;
  onClickPhonePlan(phonePlan: string): void;
};

export type phonePlanInfo = {
  telecom: string;
  phonePlans: {
    name: string;
    price: number;
    data: string;
    tel: string;
    sms: string;
    discountList: { phoneName: string; discount: number }[]; // 공시지원금
  }[];
};

export const phonePlanList: phonePlanInfo[] = [
  {
    telecom: 'SKT',
    phonePlans: [
      {
        name: '프라임 플러스',
        price: 99000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 420000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 580000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 480000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
      {
        name: '플래티넘',
        price: 125000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 420000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 580000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 480000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
    ],
  },
  {
    telecom: 'KT',
    phonePlans: [
      {
        name: '스페셜',
        price: 100000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 400000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 600000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 500000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
      {
        name: '스페셜 초이스',
        price: 110000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 400000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 600000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 500000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
    ],
  },
  {
    telecom: 'LG',
    phonePlans: [
      {
        name: '프리미어 레귤러',
        price: 95000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 450000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 600000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 500000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
      {
        name: '프리미어 플러스',
        price: 105000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 450000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 600000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 500000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
      {
        name: '프리미어 슈퍼',
        price: 115000,
        data: '완전 무제한',
        tel: '유무선 무제한 (부가 음성 300분)',
        sms: '문자 무제한',
        discountList: [
          { phoneName: 'iPhone 15', discount: 450000 },
          { phoneName: 'iPhone 15 Plus', discount: 229000 },
          { phoneName: 'iPhone 15 Pro', discount: 229000 },
          { phoneName: 'iPhone 15 Pro Max', discount: 229000 },
          { phoneName: '갤럭시 Z 플립5', discount: 600000 },
          { phoneName: '갤럭시 Z 폴드5', discount: 500000 },
          { phoneName: '갤럭시 S23', discount: 500000 },
          { phoneName: '갤럭시 S23+', discount: 500000 },
          { phoneName: '갤럭시 S23 Ultra', discount: 500000 },
        ],
      },
    ],
  },
];

export const PhonePlanDialog = ({
  nextTelecom,
  openPhonePlan,
  onClickOpenPhonePlan,
  onClickPhonePlan,
}: PhonePlanDialogProps) => {
  const telecomImgUrl = nextTelecom === 'SKT' ? 'skt.png' : nextTelecom === 'KT' ? 'kt.png' : 'lg.png';
  const telecomPhonePlanInfo: phonePlanInfo | undefined = phonePlanList.find((x) => x.telecom === nextTelecom);
  return (
    <Dialog open={openPhonePlan}>
      <div className='p-4'>
        <div className='flex items-center'>
          <div className='flex items-center text-2xl font-bold'>
            <img src={telecomImgUrl} alt={nextTelecom} /> <span className='ml-2'>요금표</span>
          </div>
          <div className='cursor-pointer ml-auto' onClick={() => onClickOpenPhonePlan()}>
            <CloseIcon />
          </div>
        </div>
        <DialogContent className='!px-0'>
          <Grid container spacing={1}>
            {telecomPhonePlanInfo?.phonePlans.map((phonePlanInfo) => (
              <Grid item xs={12} key={phonePlanInfo.name}>
                <div
                  className='bg-neutral-100 rounded-xl p-4 cursor-pointer hover:bg-neutral-200 break-keep'
                  onClick={() => onClickPhonePlan(phonePlanInfo.name)}
                >
                  <p className='text-2xl font-bold mb-1'>{phonePlanInfo.name}</p>
                  <p>
                    데이터 <span className='text-red-500 font-bold'>{phonePlanInfo.data}</span>
                  </p>
                  <p>음성 {phonePlanInfo.tel}</p>
                  <p>{phonePlanInfo.sms}</p>
                  <p className='font-bold text-right text-xl text-red-500'>
                    월 {numberWithCommas(phonePlanInfo.price)}원
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
