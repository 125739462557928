import { ChangePwdBox } from 'components/ChangePwd/ChangePwdBox';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { Template } from 'components/common/Template';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SessionInfo } from 'types';

export const ChangePwdPage = () => {
  const client = useQueryClient();
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionInfo) {
      navigate('/sign-in');
    }
  }, [navigate, sessionInfo]);

  if (!sessionInfo) return null;

  return (
    <Template header={<Header />} footer={<Footer />}>
      <div className='container mx-auto max-sm:px-8 max-w-lg my-16'>
        <ChangePwdBox />
      </div>
    </Template>
  );
};
