import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

const steps: { label: string; desc: string; imgUrl?: string }[] = [
  {
    label: '사전예약 혹은 구매신청',
    desc: '원하시는 모델을 선택하시고, 유형에 맞게 [신규가입/번호이동/기기변경] 작성해 주세요.',
    imgUrl: '/sammy-remote-work.gif',
  },
  {
    label: '해피콜',
    desc: '작성해주신 내용을 기반으로 전문 상담원이 전화를 드려 고객님께 혜택이 많은 쪽으로 안내하여 개통을 진행해 드립니다.',
    imgUrl: '/sammy-service-support.gif',
  },
  {
    label: '사은품 및 상품 수령',
    desc: '휴대폰과 사은품이 발송되며, 택배사 사정에 따라 1~2일 소요됩니다. 선배송 후개통 원칙으로 상담원의 안내에 따라 개통되시기 전에 단말기와 사은품을 받아보시고, 개통 후 사용하시던 유심칩을 바꿔 끼워서 사용하시기 바랍니다.',
    imgUrl: '/sammy-delivery.gif',
  },
];

export const StepBox = () => {
  return (
    <div className='py-24 border-b border-neutral-200'>
      <h1 className='text-4xl font-bold text-center mb-8'>구매 진행 과정</h1>
      <div>
        <Stepper
          activeStep={2}
          alternativeLabel
          connector={null}
          className='max-lg:!flex-col !justify-center !items-start max-lg:!items-center'
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                icon={<img className='h-64' src={step.imgUrl} alt={step.label} />}
                className='flex flex-col text-center'
              >
                <p className='text-xl font-bold'>{step.label}</p>
              </StepLabel>
              <p className='text-center max-w-xs mx-auto break-keep text-neutral-800 text-sm my-2 leading-6'>
                {step.desc}
              </p>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};
