import { createClient } from '@supabase/supabase-js';

export const supabaseClient = createClient(process.env.REACT_APP_S_U || '', process.env.REACT_APP_S_K || '');

export const addReserve = async (
  phoneModel: string,
  phoneColor: string,
  phoneCapacity: string,
  prevTelecom: string,
  nextTelecom: string,
  phonePlan: string,
  discountType: string,
  paymentType: string,
  name: string,
  phoneNumber: string,
  birth: string,
  address: string,
  reqDetail: string,
  mvno: boolean,
  selectGift: string,
  affiliateCard: string,
  userId?: string | undefined,
) => {
  const { error } = await supabaseClient.from('reservations').insert({
    user_id: userId || undefined,
    phone_model: phoneModel,
    phone_color: phoneColor,
    phone_capacity: phoneCapacity,
    prev_telecom: prevTelecom,
    next_telecom: nextTelecom,
    phone_plan: phonePlan,
    discount_type: discountType,
    payment_type: paymentType,
    name: name,
    phone_number: phoneNumber,
    birth: birth,
    address: address,
    request_detail: reqDetail,
    change_mvno: mvno,
    select_gift: selectGift,
    affiliate_card: affiliateCard,
  });

  return error;
};

export const getSession = async () => {
  try {
    const {
      data: { session },
    } = await supabaseClient.auth.getSession();

    return session;
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

export const signOut = async () => {
  try {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      console.log(error);
      return false;
    }

    return true;
  } catch (error: any) {
    console.log(error);
    return false;
  }
};

export const updatePassword = async (newPwd: string) => {
  try {
    const { error } = await supabaseClient.auth.updateUser({ password: newPwd });

    if (error) {
      console.log(error.message);
      return false;
    }

    return true;
  } catch (error: any) {
    console.log(error);
    return false;
  }
};

export const getReservations = async (user_id: string) => {
  try {
    if (!user_id) {
      return [];
    }
    const { data } = await supabaseClient.from('reservations').select('*').eq('user_id', user_id);

    return data;
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

export const getReviews = async (userId?: string, reservationId?: string) => {
  try {
    let findOptions = {};

    if (userId) {
      Object.assign(findOptions, { user_id: userId });
    }
    if (reservationId) {
      Object.assign(findOptions, { reservation_id: userId });
    }
    const { data, error } = await supabaseClient.from('reviews').select('*').match(findOptions);

    if (error) {
      console.log(error);
      return [];
    }

    return data;
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

export const addReview = async (
  userId: string,
  reservationId: string,
  name: string,
  content: string,
  rate: number,
  profileImgUrl?: string,
  imgUrls?: string[],
) => {
  try {
    if (!userId) {
      return false;
    }
    if (!reservationId) {
      return false;
    }

    const { error } = await supabaseClient.from('reviews').insert({
      user_id: userId,
      reservation_id: reservationId,
      content: content,
      rate: rate,
      profile_img_url: profileImgUrl,
      img_urls: imgUrls,
      name: name,
    });

    if (error) {
      console.log(error);
      return false;
    }

    return true;
  } catch (error: any) {
    console.log(error);
    return false;
  }
};

export const uploadReviewImage = async (
  uploadImgBytes: Uint8Array,
  uploadImgExtension: string,
  saveFilePath: string,
) => {
  try {
    const { data, error } = await supabaseClient.storage
      .from('review_images')
      .upload(saveFilePath, uploadImgBytes, { contentType: `image/${uploadImgExtension}`, upsert: true });
    if (error) {
      return '';
    }

    return data.path;
  } catch (error: any) {
    return '';
  }
};

export const getReviewImageUrl = async (imgPath: string) => {
  try {
    const { data } = await supabaseClient.storage.from('review_images').getPublicUrl(imgPath);
    if (!data) {
      return '';
    }
    return data.publicUrl;
  } catch (error: any) {
    return '';
  }
};

export const getPhoneModels = async (modelName: string) => {
  try {
    const { data } = await supabaseClient
      .from('phone_models')
      .select('*')
      .order('phone_name')
      .filter('phone_name', 'like', `%${modelName}%`);
    if (!data) {
      return [];
    }

    return data;
  } catch (error: any) {
    return [];
  }
};
