import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ComparePriceBox } from 'components/Main/ComparePriceBox';

type ComparePlanDialogProps = {
  openComparePlan: boolean;
  onClickOpenComparePlan(): void;
};

export const ComparePlanDialog = ({ openComparePlan, onClickOpenComparePlan }: ComparePlanDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog open={openComparePlan} fullWidth maxWidth={'xl'} fullScreen={fullScreen}>
      <div className='cursor-pointer ml-auto p-4' onClick={() => onClickOpenComparePlan()}>
        <CloseIcon fontSize='large' />
      </div>
      <ComparePriceBox />
    </Dialog>
  );
};
