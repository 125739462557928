import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className='h-screen bg-black text-white'>
      <Helmet>
        <title>페이지를 찾을 수 없습니다 | 와우 스토어</title>
      </Helmet>
      <div className='h-full flex flex-col justify-center items-center'>
        <h3 className='font-bold text-6xl text-center max-lg:text-4xl max-sm:text-2xl break-keep'>
          요청하신 페이지를 찾을 수 없습니다.
        </h3>
        <div className='my-8'>
          <Button
            variant='contained'
            className='bg-gradient-to-r from-stone-800 via-stone-500 to-sky-900 !text-white !text-lg font-bold'
            onClick={() => navigate(-1)}
          >
            뒤로가기
          </Button>
        </div>
      </div>
    </div>
  );
};
