import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import { supabaseClient } from 'apis/supabaseApi';
import React, { useState } from 'react';

const views: { id: ViewType; title: string }[] = [
  { id: 'sign_in', title: '로그인' },
  { id: 'sign_up', title: '회원가입' },
  { id: 'magic_link', title: '매직링크' },
  { id: 'forgotten_password', title: '비밀번호 찾기' },
  { id: 'update_password', title: '비밀번호 변경' },
  { id: 'verify_otp', title: 'OTP 인증' },
];

export const SignInBox = () => {
  const [view, setView] = useState(views[0]);

  return (
    <div>
      <h1 className='text-4xl font-bold my-8'>{view.title}</h1>
      <Auth
        supabaseClient={supabaseClient}
        view={view.id}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: 'rgb(245 158 11);',
                brandAccent: 'rgb(217 119 6);',
              },
            },
          },
        }}
        redirectTo={window.location.origin}
        showLinks={false}
        providers={['google', 'kakao']}
        socialLayout='horizontal'
        localization={{
          variables: {
            sign_up: {
              email_label: '이메일',
              password_label: '사용하실 비밀번호',
              email_input_placeholder: '사용하실 이메일을 입력해 주세요.',
              password_input_placeholder: '사용하실 비밀번호를 입력해 주세요.',
              button_label: '회원가입',
              loading_button_label: '회원가입 요청 중 ...',
              social_provider_text: '{{provider}}로 가입하기',
              link_text: '아직 회원가입을 하지 않으셨다면 회원가입 해주세요.',
              confirmation_text: '입력하신 이메일로 가입 확인 인증 메일을 전송했습니다. 확인해 주세요.',
            },
            sign_in: {
              email_label: '이메일',
              password_label: '비밀번호',
              email_input_placeholder: '이메일을 입력해 주세요.',
              password_input_placeholder: '비밀번호를 입력해 주세요.',
              button_label: '로그인',
              loading_button_label: '로그인 시도 중...',
              link_text: '이미 회원가입을 하셨다면 로그인해 주세요.',
              social_provider_text: '{{provider}}로 로그인',
            },
            forgotten_password: {
              email_label: '이메일',
              password_label: '비밀번호',
              email_input_placeholder: '가입하신 이메일을 입력해 주세요.',
              button_label: '비밀번호 초기화',
              loading_button_label: '비밀번호 초기화 메일을 보내는 중...',
              link_text: '비밀번호를 잊으셨나요?',
              confirmation_text: '입력하신 이메일로 비밀번호 초기화 메일을 전송했습니다. 확인해 주세요.',
            },
            update_password: {
              password_label: '새로운 비밀번호',
              password_input_placeholder: '새로운 비밀번호를 입력해 주세요.',
              button_label: '비밀번호 변경하기',
              loading_button_label: '비밀번호 변경중...',
              confirmation_text: '비밀번호가 성공적으로 변경되었습니다.',
            },
          },
        }}
      />
      <div className='flex justify-center items-center text-sm text-neutral-600'>
        {view.id === 'sign_up' && (
          <p
            onClick={() => {
              setView(views[0]);
            }}
            className='cursor-pointer inline-block my-4'
          >
            로그인하기
          </p>
        )}
        {view.id === 'sign_in' && (
          <div className='flex items-center my-4'>
            <p
              onClick={() => {
                setView(views[3]);
              }}
              className='cursor-pointer inline-block mx-2'
            >
              비밀번호 찾기
            </p>
            <p className='text-xs text-neutral-400'>|</p>
            <p
              onClick={() => {
                setView(views[1]);
              }}
              className='cursor-pointer inline-block mx-2'
            >
              회원가입
            </p>
          </div>
        )}
        {view.id === 'forgotten_password' && (
          <p
            onClick={() => {
              setView(views[0]);
            }}
            className='cursor-pointer inline-block my-2'
          >
            로그인하기
          </p>
        )}
      </div>
    </div>
  );
};
