import { useQueryClient } from 'react-query';
import React from 'react';
import { Link } from 'react-router-dom';
import { SessionInfo } from 'types';
import { signOut } from 'apis/supabaseApi';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { enqueueSnackbar } from 'notistack';
export const UserAvatar = () => {
  const UserMenuRef = React.useRef<HTMLDivElement | null>(null);
  const client = useQueryClient();
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);

  const [isUserOpen, setIsUserOpen] = React.useState(false);

  const onClickUserMenu = () => {
    setIsUserOpen(!isUserOpen);
  };

  const onClickSignOut = async () => {
    const result = await signOut();

    if (!result) {
      return enqueueSnackbar('로그아웃을 실패했습니다. 잠시 후 다시 시도해 주세요.', { variant: 'warning' });
    }
    window.location.reload();
  };

  React.useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
      if (isUserOpen && !UserMenuRef.current?.contains(e.target)) setIsUserOpen(false);
    };
    document.addEventListener('click', handleOutsideClose);

    return () => document.removeEventListener('click', handleOutsideClose);
  }, [isUserOpen]);

  return (
    <div className='relative ml-auto' ref={UserMenuRef}>
      <div
        className='bg-neutral-100 p-1 rounded-full cursor-pointer hover:bg-neutral-200'
        onClick={() => onClickUserMenu()}
      >
        <img
          alt=''
          src={
            sessionInfo?.user.user_metadata.avatar_url
              ? sessionInfo?.user.user_metadata.avatar_url
              : 'https://xsgames.co/randomusers/avatar.php?g=pixel'
          }
          className='h-12 w-12 rounded-full'
        />
      </div>
      {isUserOpen && (
        <div className='absolute bg-neutral-50 p-4 rounded-lg top-16 right-0 w-52 shadow-xl border border-neutral-200 z-50'>
          <div className='px-2'>
            <div className='my-2 p-2 rounded-lg'>
              <span className='font-bold'>{sessionInfo?.user.user_metadata.full_name}</span>님
            </div>
            <div>
              <Link to={'/mypage'}>
                <div className='flex items-center cursor-pointer my-2 p-2 rounded-lg hover:bg-neutral-200'>
                  <PermIdentityOutlinedIcon fontSize='small' />
                  <p className='ml-2'>마이페이지</p>
                </div>
              </Link>
              <div
                className='flex items-center cursor-pointer my-2 p-2 rounded-lg hover:bg-neutral-200'
                onClick={() => onClickSignOut()}
              >
                <LogoutOutlinedIcon fontSize='small' />
                <p className='ml-2'>로그아웃</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
