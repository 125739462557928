import { Avatar, Rating } from '@mui/material';

import React from 'react';

const reviews = [
  {
    content:
      '쿠팡, 11번가 이곳저곳 다 알아보고 했는데 여기가 제일 저렴한거 같아서 했어요!! 아이폰 15 너무 기대됩니다! 빨리 받고싶어요',
    name: '김*원',
    rate: 5,
    date: '2023.10.6',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
    // profileImgUrl: 'http://k.kakaocdn.net/dn/CUPSm/btrKUu2QEnC/1ein76r9AXMEAMx8uqkD40/img_640x640.jpg',
  },
  {
    content: '핸드폰 사전예약은 처음 해보는데 설명도 친절하게 잘 알려주시고 너무 좋았어요!',
    name: '김*희',
    rate: 5,
    date: '2023.10.6',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
  },
  {
    content:
      '이번에 아이폰 15 나오면 바꾸려고 기다리다가 제일 저렴한곳으로 찾아서 했는데 사은품도 괜찮고 만족스러운것같아요',
    name: '이*진',
    rate: 5,
    date: '2023.10.6',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
  },
  {
    content: '사전예약 신청 하자마자 거의 바로 연락주시고 상담도 친절하고 이해하기 쉽게 설명해주셔서 좋았습니다',
    name: '이*찬',
    rate: 5,
    date: '2023.10.7',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
  },
  {
    content: '다음 핸드폰도 사전예약 여기서 해야겠어요 가격도 너무 착하고 핸드폰 맛집이네요 ㅋㅋ',
    name: '김*수',
    rate: 5,
    date: '2023.10.7',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
  },
  {
    content:
      '아직 잘 알려지지 않은 곳 같은데 상담해주시는 직원분도 친절하시고 사은품도 잘 챙겨주시는 것 같아서 기분 좋고 기대됩니다. 아이폰 빨리 받아서 쓰고싶어요',
    name: '이*경',
    rate: 5,
    date: '2023.10.7',
    profileImgUrl: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
  },
];

export const ReviewBanner = ({ speed = 25000 }) => {
  return (
    <div className='my-16'>
      <div className='flex items-center justify-center font-bold text-4xl text-center my-16 max-sm:text-2xl'>
        <div>
          <p>
            <span className='text-amber-500'>와우</span>스토어를 이용해주신
          </p>
          <p>고객님들의 소중한 리뷰</p>
        </div>
      </div>
      <div className='relative w-full overflow-hidden h-80'>
        <div className='absolute flex'>
          <BannerSection speed={speed} />
          <BannerSection speed={speed} />
          <BannerSection speed={speed} />
        </div>
      </div>
    </div>
  );
};

export const BannerSection = ({ speed }: { speed: number }) => {
  return (
    <section className='flex' style={{ animation: `swipe ${speed}ms linear infinite backwards` }}>
      {reviews.map((review) => (
        <div
          key={review.name}
          className='flex flex-col justify-between mx-4 my-4 min-w-[300px] border border-neutral-200 shadow-xl rounded-xl p-4'
        >
          <div className='text-xl flex'>
            <p>{review.name}님</p>
            <Avatar className='ml-auto border border-neutral-300' src={review.profileImgUrl} />
          </div>
          <div className='font-bold my-4 break-keep'>
            <q>{review.content}</q>
          </div>
          <div className='flex items-center'>
            <div>
              <Rating value={review.rate} readOnly />
            </div>
            <div className='ml-auto'>{review.date}</div>
          </div>
        </div>
      ))}
    </section>
  );
};
