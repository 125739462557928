import { Button, TextField } from '@mui/material';
import { signOut, updatePassword } from 'apis/supabaseApi';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ChangePwdBox = () => {
  const [newPwd, setNewPwd] = React.useState('');
  const navigate = useNavigate();

  const onChangeNewPwd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPwd(event.target.value);
  };

  const onClickUpdatePwd = async () => {
    if (newPwd.length < 6) {
      return enqueueSnackbar('비밀번호를 6자리 이상 입력해 주세요.', { variant: 'warning' });
    }
    const result = await updatePassword(newPwd);

    if (result) {
      await signOut();
      enqueueSnackbar('비밀번호를 성공적으로 변경했습니다. 다시 로그인해 주세요.', { variant: 'info' });
      setTimeout(() => {
        navigate(0);
      }, 1500);
    } else {
      enqueueSnackbar('비밀번호 변경을 실패했습니다. 잠시 후 다시 시도해 주세요.', { variant: 'error' });
    }
  };
  return (
    <div>
      <h1 className='text-4xl font-bold my-8'>비밀번호 변경</h1>
      <div>
        <p className='text-sm text-neutral-600 my-2'>비밀번호 변경하기</p>
        <TextField
          label='새로운 비밀번호'
          variant='outlined'
          fullWidth
          onChange={onChangeNewPwd}
          type='password'
          placeholder='새로운 비밀번호를 입력해 주세요.'
        />
      </div>
      <div className='my-4'>
        <Button
          onClick={() => onClickUpdatePwd()}
          variant='contained'
          fullWidth
          className='!bg-amber-500 !border-2 !border-amber-600 hover:!bg-amber-600'
        >
          비밀번호 변경하기
        </Button>
      </div>
    </div>
  );
};
