import { Badge, Grid } from '@mui/material';
import React from 'react';
export const GiftBox = () => {
  return (
    <div>
      <div className='text-center bg-neutral-100'>
        <div className='py-8'>
          <div className='my-10'>
            <h1 className='text-4xl font-bold my-2'>
              와우스토어 <span className='text-orange-500'>혜택</span>
            </h1>
            <p className='text-xl text-neutral-600 break-keep'>
              와우스토어에서 구매하시는 고객분들께 드리는 사은품 혜택{' '}
              <span className='text-red-400 font-bold text-lg'>(택1)</span>
            </p>
          </div>
          <Grid container spacing={6} justifyContent='center' alignItems='center' className='!my-10 px-8'>
            <Grid item>
              <Badge badgeContent='선택1' color='warning' anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                <div className='bg-white rounded-xl shadow-xl'>
                  <img src='/airpods3.png' alt='AirPods 3' className='h-64 rounded-xl' />
                  <p className='text-2xl font-bold py-4'>AirPods(3세대)</p>
                </div>
              </Badge>
            </Grid>
            <Grid item>
              <Badge badgeContent='선택2' color='warning' anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                <div className='bg-white rounded-xl shadow-xl'>
                  <img src='/airpods_pro.png' alt='AirPods Pro 2' className='h-64 rounded-xl' />
                  <p className='text-2xl font-bold py-4'>AirPods Pro(2세대)</p>
                </div>
              </Badge>
            </Grid>
            <Grid item>
              <Badge badgeContent='선택3' color='warning' anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                <div className='bg-white rounded-xl shadow-xl'>
                  <img src='/50000.png' alt='30만원 지급' className='sm:h-64 rounded-xl' />
                  <p className='text-2xl font-bold py-4'>최대 30만원 지원</p>
                </div>
              </Badge>
            </Grid>
          </Grid>
          <div className='text-xs text-stone-500 py-8'>
            <p>*가입 조건 및 요금제에 따라 추가금이 발생할 수 있습니다.</p>
          </div>
        </div>
      </div>
      <div className='pt-8 pb-32 px-8 bg-neutral-50 border border-neutral-200'>
        <div className='flex text-center flex-col justify-center items-center'>
          <h3 className='text-4xl max-sm:text-3xl font-bold my-16 relative break-keep'>
            구매만해도 <span className='text-orange-500 max-sm:text-4xl'>무조건 증정</span>
          </h3>
          <Grid container spacing={6} justifyContent='center' alignItems='center'>
            <Grid item>
              <div className='bg-white rounded-2xl shadow-2xl p-4'>
                <img src='/iphone-case.avif' alt='아이폰 15 케이스 및 필름' className='rounded-2xl h-64' />
                <div className='text-2xl font-bold py-4 text-neutral-900'>
                  <p>투명 케이스</p>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className='bg-white rounded-2xl shadow-2xl p-4'>
                <img src='/iphone-film.png' alt='아이폰 15 케이스 및 필름' className='rounded-2xl h-64' />
                <div className='text-2xl font-bold py-4 text-neutral-900'>
                  <p>강화유리 보호 필름</p>
                </div>
              </div>
            </Grid>
            {/* <Grid item>
              <div className='bg-white rounded-2xl shadow-2xl p-4'>
                <img src='/iceamericano.jpeg' alt='스타벅스 아이스 아메리카노 Tall' className='rounded-2xl sm:h-64' />
                <p className='text-2xl font-bold py-4 text-neutral-900'>스타벅스 아메리카노(Tall)</p>
              </div>
            </Grid> */}
            <Grid item>
              <div className='bg-white rounded-2xl shadow-2xl p-4'>
                <img src='/chager.jpeg' alt='20W 초고속 충전기' className='rounded-2xl h-64' />
                <p className='text-2xl font-bold py-4 text-neutral-900'>20W 초고속 충전기</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='text-xs text-stone-500 pt-16 text-center'>
          <p>*신청 시에 선택하신 기종에 맞게 제공됩니다.</p>
          <p>*실제 제공되는 상품은 상기 이미지와 다를 수 있습니다.</p>
        </div>
      </div>
    </div>
  );
};
