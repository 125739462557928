import { Button, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const PhoneList = () => {
  return (
    <div className='pt-8 pb-16 bg-neutral-100'>
      <h1 className='text-4xl font-bold text-center pt-12'>구매하러 가기</h1>
      <div className='py-10'>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <div className='bg-white rounded-xl shadow-xl text-center'>
              <div className='text-xl font-bold pt-8'>iPhone 15 또는 iPhone 15 Plus</div>
              <img src='/Apple-iPhone-15-Lineup-PNG.png' alt='iPhone 15 Lineup' className='h-64 rounded-xl' />
              <Link to='/reserve?modelName=iPhone 15'>
                <Button variant='contained' className='!mb-6 bg-gradient-to-r from-sky-500 via-yellow-500 to-pink-500'>
                  구매하기
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className='bg-white rounded-xl shadow-xl text-center'>
              <div className='text-xl font-bold pt-8'>iPhone 15 Pro 또는 iPhone 15 Pro Max</div>
              <img src='/Apple-iPhone-15-Pro-Lineup-PNG.png' alt='iPhone 15 Lineup' className='h-64 rounded-xl' />
              <Link to='/reserve?modelName=iPhone 15'>
                <Button variant='contained' className='!mb-6 bg-gradient-to-r from-stone-800 via-stone-500 to-sky-900'>
                  구매하기
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
