import { Button, Dialog, Grid, Rating, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from 'react-query';
import { ReservationInfo, SessionInfo, UploadImageInfo } from 'types';
import { addReview, getReviewImageUrl, uploadReviewImage } from 'apis/supabaseApi';
import { enqueueSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

type ReviewDialogProps = {
  openReview: boolean;
  reservationId: string;
  onClickOpenReview(): void;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ReviewDialog = ({ openReview, reservationId, onClickOpenReview }: ReviewDialogProps) => {
  const [reviewRate, setReviewRate] = useState(5);
  const [reviewContent, setReviewContent] = useState('');
  const [uploadImages, setUploadImages] = useState<UploadImageInfo[]>([]);

  const client = useQueryClient();
  const reservationList = client.getQueryData<ReservationInfo[]>(['reservations']);
  const reservationInfo = reservationList?.find((x) => x.id === reservationId);
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const reviewDesc =
    reviewRate >= 5
      ? '최고예요'
      : reviewRate >= 4
      ? '만족해요'
      : reviewRate >= 3
      ? '괜찮아요'
      : reviewRate >= 2
      ? '그저 그래요'
      : '별로예요';

  const onChangeReviewRate = (_reviewRate: number) => {
    setReviewRate(_reviewRate);
  };

  const onChangeReviewContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewContent(event.target.value);
  };

  const onChangeImageFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = (event.target as HTMLInputElement).files as FileList;
    if (fileList) {
      const newFiles: { name: string; extension: string; base64: string }[] = [];

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result && typeof e.target.result === 'string') {
            const base64String = e.target.result.split(',')[1];
            const extension = file.name.split('.').pop() || ''; // 확장자 추출
            newFiles.push({ name: file.name, extension, base64: base64String });

            if (newFiles.length === fileList.length) {
              setUploadImages((prev) => prev.concat(...newFiles));
            }
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const onClickDeleteImage = (name: string) => {
    setUploadImages(uploadImages.filter((e) => e.name !== name));
  };

  function base64ToBytes(base64: string) {
    const binaryString = atob(base64); // base64 디코딩
    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }

  const onClickRegReview = async () => {
    if (!reviewContent || reviewContent.length < 15) {
      return enqueueSnackbar('최소 15자 이상 작성해 주세요.', { variant: 'warning' });
    }

    let imgUrls: string[] = [];

    await uploadImages.forEach((imageInfo) => {
      const saveFilePath = `/${reservationId}/${imageInfo.name}`;
      const result = uploadReviewImage(base64ToBytes(imageInfo.base64), imageInfo.extension, saveFilePath);
      if (!result) {
        enqueueSnackbar(`${imageInfo.name} 파일 업로드를 실패했습니다.`, { variant: 'warning' });
      }
      imgUrls.push(saveFilePath);
    });

    let publicImgUrls: string[] = [];

    for (let i = 0; i < imgUrls.length; i++) {
      const element = imgUrls[i];
      const publicUrl = await getReviewImageUrl(element);
      publicImgUrls.push(publicUrl);
    }

    const result = await addReview(
      sessionInfo?.user.id || '',
      reservationId,
      sessionInfo?.user.user_metadata.full_name,
      reviewContent,
      reviewRate,
      sessionInfo?.user.user_metadata.avatar_url
        ? sessionInfo?.user.user_metadata.avatar_url
        : 'https://xsgames.co/randomusers/avatar.php?g=pixel',
      publicImgUrls,
    );

    if (result) {
      enqueueSnackbar('리뷰 등록을 완료했습니다.', { variant: 'info' });
    } else {
      enqueueSnackbar('리뷰 등록을 실패했습니다. 잠시 후 다시 시도해 주세요.', { variant: 'error' });
    }

    onClickOpenReview();
  };

  return (
    <Dialog open={openReview} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
      <div className='p-8'>
        <div className='flex items-center'>
          <h1 className='font-bold text-2xl'>리뷰 작성</h1>
          <div className='cursor-pointer ml-auto' onClick={() => onClickOpenReview()}>
            <CloseIcon fontSize='large' />
          </div>
        </div>
        <div>
          <div className='my-8'>
            <p className='font-bold text-2xl break-keep'>
              {reservationInfo?.phone_model} {reservationInfo?.phone_capacity} {reservationInfo?.phone_color}
            </p>
          </div>
          <div className='flex flex-wrap items-center my-8'>
            <div className='font-bold text-xl flex flex-wrap my-1'>
              <p className='mr-1'>상품은 어떠셨나요?</p>
              <p className='font-normal'>{reviewDesc}</p>
            </div>
            <div className='ml-auto flex items-center my-1'>
              <Rating
                value={reviewRate}
                onChange={(event, newValue) => {
                  onChangeReviewRate(newValue || reviewRate);
                }}
                precision={0.5}
                size='large'
              />
            </div>
          </div>
          <div className='flex flex-wrap flex-col my-8'>
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip title='사진 업로드하기'>
                  <Button component='label' variant='contained' className='h-24 w-24'>
                    <AddPhotoAlternateIcon fontSize='large' />
                    <VisuallyHiddenInput type='file' onChange={onChangeImageFiles} accept='image/*' multiple />
                  </Button>
                </Tooltip>
              </Grid>
              {uploadImages.map((imageInfo) => (
                <Grid
                  item
                  className='group mx-2 relative cursor-pointer'
                  key={imageInfo.name}
                  onClick={() => onClickDeleteImage(imageInfo.name)}
                >
                  <CloseIcon className='absolute top-1 right-1 rounded-full bg-neutral-300 text-neutral-700 !hidden group-hover:!block' />
                  <div className='border border-neutral-300 rounded-md'>
                    <img
                      src={`data:image/${imageInfo.extension};base64,${imageInfo.base64}`}
                      alt=''
                      className='h-24 w-24 rounded-md'
                      key={imageInfo.name}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='my-8'>
            <TextField
              multiline
              rows={6}
              fullWidth
              placeholder='최소 15자 이상 작성해 주세요.'
              value={reviewContent}
              onChange={onChangeReviewContent}
            />
          </div>
          <div className='my-8'>
            <Button variant='contained' fullWidth onClick={() => onClickRegReview()}>
              등록하기
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
