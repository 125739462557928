import { ReserveBox } from 'components/Reserve/ReserveBox';
import { Footer } from 'components/common/Footer';
import { Template } from 'components/common/Template';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocationQuery } from 'hooks/useQuery';
import { Header } from 'components/common/Header';
import { useQuery } from 'react-query';
import { getPhoneModels } from 'apis/supabaseApi';
import { Backdrop, CircularProgress } from '@mui/material';

export const ReservePage = () => {
  const { pathname } = useLocation();
  const query = useLocationQuery();
  const navigate = useNavigate();
  const modelName = query.get('modelName');
  const { isLoading, data } = useQuery(['phone_models', modelName], () => getPhoneModels(modelName || ''));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!modelName || data?.length === 0) {
    navigate(-1);
    return null;
  }

  if (isLoading)
    return (
      <Backdrop sx={{ bgcolor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );

  return (
    <Template header={<Header />} footer={<Footer />}>
      <div className='container mx-auto max-sm:px-8'>
        <Helmet>
          <title>{modelName} 시리즈 구매하기 | 와우 스토어</title>
        </Helmet>
        <ReserveBox modelName={modelName} />
      </div>
    </Template>
  );
};
