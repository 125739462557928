import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SessionInfo } from 'types';

type MyPageMenuProps = {
  sessionInfo?: SessionInfo;
};

const menuLinks = [
  { label: '구매내역 조회', link: '/mypage/reservation-list' },
  { label: '리뷰 조회', link: '/mypage/review-list' },
];

export const MyPageMenu = ({ sessionInfo }: MyPageMenuProps) => {
  const { pathname } = useLocation();
  return (
    <div className='pr-4 my-8'>
      <div className='font-bold text-2xl mb-8'>{sessionInfo?.user.user_metadata.full_name || '고객'}님</div>
      <div>
        <h1 className='font-bold text-xl'>나의 구매내역 정보</h1>
        <div>
          {menuLinks.map((x) => (
            <div key={x.label} className={`my-4 text-stone-800 ${pathname === x.link && 'font-bold'}`}>
              <Link to={x.link}>{x.label}</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
