import { Grid } from '@mui/material';
import { numberWithCommas } from 'common/numberWithCommas';
import React from 'react';
import { PhoneModelInfo } from 'types';

type PhoneModelProps = {
  phoneModelList: PhoneModelInfo[];
  phoneModel: string;
  phoneColor: string;
  phoneCapacity: string;
  onClickPhoneModel(model: string): void;
  onClickPhoneColor(color: string): void;
  onClickPhoneCapacity(size: string): void;
};

export const PhoneModel = ({
  phoneModelList,
  phoneModel,
  phoneColor,
  phoneCapacity,
  onClickPhoneModel,
  onClickPhoneColor,
  onClickPhoneCapacity,
}: PhoneModelProps) => {
  const modelInfo: PhoneModelInfo | undefined = phoneModelList?.find(
    (iphoneInfo) => iphoneInfo.phone_name === phoneModel,
  );

  return (
    <Grid container spacing={4} direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={6}>
        <div className='bg-neutral-100 rounded-xl'>
          {modelInfo?.img_urls && <img src={modelInfo?.img_urls[0]} alt='' className='sm:h-96 mx-auto' />}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Grid container spacing={1} direction='column'>
            {phoneModelList?.map((modelInfo) => (
              <Grid item key={modelInfo.phone_name}>
                <div
                  className={`px-8 py-4 rounded-lg border-2 cursor-pointer hover:border-blue-500 ${
                    modelInfo.phone_name === phoneModel ? 'border-blue-500' : 'border-neutral-300'
                  }`}
                  onClick={() => onClickPhoneModel(modelInfo.phone_name)}
                >
                  <div className='text-lg font-bold'>{modelInfo.phone_name}</div>
                  <div className='text-neutral-600 text-sm'>{modelInfo.desc}</div>
                </div>
              </Grid>
            ))}
          </Grid>
          <div className='my-4'>
            <p className='text-xl font-bold'>색상 {phoneColor && `- ${phoneColor}`}</p>
            <div className='my-2'>
              <Grid container spacing={1}>
                {modelInfo?.colors.map((colorInfo) => (
                  <Grid item key={colorInfo.label}>
                    <div
                      className={`p-6 border-4 cursor-pointer rounded-full hover:border-blue-500 ${
                        colorInfo.label === phoneColor ? 'border-blue-500' : 'border-neutral-100'
                      }`}
                      style={{ backgroundColor: colorInfo.color }}
                      onClick={() => onClickPhoneColor(colorInfo.label)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <div className='my-4'>
            <p className='text-xl font-bold'>용량</p>
            <div className='my-2'>
              <Grid container spacing={1} direction={{ xs: 'column', lg: 'row' }}>
                {modelInfo?.capacities.map((capacity) => (
                  <Grid item xs={4} key={capacity.size}>
                    <div
                      className={`flex justify-between items-center px-8 py-4 rounded-lg border-2 cursor-pointer hover:border-blue-500 ${
                        capacity.size === phoneCapacity ? 'border-blue-500' : 'border-neutral-300'
                      }`}
                      onClick={() => onClickPhoneCapacity(capacity.size)}
                    >
                      <div className='text-lg font-bold'>{capacity.size}</div>
                      <div className='text-neutral-600 text-xs'>₩{numberWithCommas(capacity.price)}</div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
