import { Grid } from '@mui/material';
import { getReservations, getReviews } from 'apis/supabaseApi';
import { MyPageMenu } from 'components/MyPage/MyPageMenu';
import { ReviewList } from 'components/ReviewList/ReviewList';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { Template } from 'components/common/Template';
import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SessionInfo } from 'types';

export const ReviewListPage = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);
  const { isLoading, data } = useQuery(['reservations'], () => getReservations(sessionInfo?.user.id || ''));
  const { isLoading: isReviewLoading, data: reviewDatas } = useQuery(['reviews'], () =>
    getReviews(sessionInfo?.user.id || ''),
  );

  useEffect(() => {
    if (!sessionInfo) {
      navigate('/sign-in');
    }
  }, [navigate, sessionInfo]);

  if (!sessionInfo) return null;

  return (
    <Template header={<Header />} footer={<Footer />}>
      <div className='container mx-auto max-sm:px-8 py-8 min-h-screen'>
        <Grid container direction={{ xs: 'column', lg: 'row' }}>
          <Grid item xs={3}>
            <MyPageMenu sessionInfo={sessionInfo} />
          </Grid>
          <Grid item xs={9}>
            <ReviewList
              reservationDatas={data || []}
              reviewDatas={reviewDatas || []}
              isLoadingReservation={isLoading}
              isLoadingReview={isReviewLoading}
            />
          </Grid>
        </Grid>
      </div>
    </Template>
  );
};
