import { Button } from '@mui/material';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { Template } from 'components/common/Template';
import { useLocationQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';

export const ReserveSuccessPage = () => {
  const { pathname } = useLocation();
  const query = useLocationQuery();
  const modelName = query.get('modelName') || '휴대폰';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Template header={<Header />} footer={<Footer />}>
      <div className='h-screen w-screen flex items-center flex-col'>
        <Helmet>
          <title>{modelName} 시리즈 구매 신청 완료 | 와우 스토어</title>
        </Helmet>
        <div>
          <img src='/wow_animation.gif' alt='WoW' />
        </div>
        <div className='px-4 text-center'>
          <p className='text-xl font-bold break-keep my-1'>고객님께서 신청하신 정보를 검토한 후 연락 드리겠습니다.</p>
          <p className='text-xl font-bold break-keep my-1'>와우 스토어를 통해 구매 신청 해주셔서 감사합니다.</p>
        </div>
        <div className='text-center py-8'>
          <Link to='/'>
            <Button variant='contained' className='!bg-amber-500 hover:!bg-amber-600 !text-white !text-lg font-bold'>
              홈으로
            </Button>
          </Link>
        </div>
      </div>
    </Template>
  );
};
