import React, { useState } from 'react';
import { ReservationInfo, ReviewInfo } from 'types';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import dayjs from 'dayjs';
import { Button, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReviewDialog } from './ReviewDialog';

type ReservationListProps = {
  reservationDatas: ReservationInfo[];
  reviewDatas: ReviewInfo[];
  isLoadingReservation: boolean;
  isLoadingReview: boolean;
};

export const ReservationList = ({
  reservationDatas,
  reviewDatas,
  isLoadingReservation,
  isLoadingReview,
}: ReservationListProps) => {
  const [openReview, setOpenReview] = useState(false);
  const [reservationId, setReservationId] = useState('');

  const columns: MUIDataTableColumn[] = [
    {
      name: 'created_at',
      label: '신청 날짜',
      options: {
        customBodyRender: (value: string) => {
          return <div>{String(dayjs(value).format('YYYY.MM.DD'))}</div>;
        },
        sort: false,
      },
    },
    {
      name: 'phone_model',
      label: '신청 내역',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className='font-bold'>
              {reservationDatas![dataIndex].phone_model} {reservationDatas![dataIndex].phone_capacity}{' '}
              {reservationDatas![dataIndex].phone_color}
            </div>
          );
        },
        sort: false,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (value: string) => {
          const isExistReview = reviewDatas?.find((x) => x.reservation_id === value);
          return (
            <div className='text-right'>
              {!isExistReview ? (
                <Button variant='contained' onClick={() => onClickOpenReview(value)}>
                  리뷰쓰기
                </Button>
              ) : (
                <Button variant='contained'>리뷰 작성완료</Button>
              )}
            </div>
          );
        },
        sort: false,
      },
    },
  ];

  const onClickOpenReview = (reservationId?: string) => {
    setReservationId(reservationId || '');
    setOpenReview(!openReview);
  };

  return (
    <div className='mb-16'>
      <div className='flex items-end font-bold text-xl border-b-4 border-black pb-2'>
        <p>구매 신청 내역</p>
        <Link to={'/mypage/reservation-list'} className='ml-auto'>
          <p className='text-sm text-stone-800'>더 보기 &gt;</p>
        </Link>
      </div>
      <div className='my-4'>
        <MUIDataTable
          title={''}
          columns={columns}
          data={(reservationDatas as ReservationInfo[]) || []}
          components={{
            TableToolbar(props) {
              return null;
            },
          }}
          options={{
            responsive: 'simple',
            selectableRows: 'none',
            textLabels: {
              body: {
                noMatch:
                  isLoadingReservation && isLoadingReview ? (
                    <CircularProgress size={26} color='inherit' />
                  ) : (
                    '구매 내역이 없습니다.'
                  ),
              },
              pagination: {
                next: '다음 페이지',
                previous: '이전 페이지',
                rowsPerPage: '페이지 단위: ',
                displayRows: '중에',
              },
              toolbar: {
                search: '검색',
                downloadCsv: 'CSV로 다운로드',
                print: '인쇄',
                viewColumns: '컬럼 옵션',
                filterTable: '필터',
              },
            },
          }}
        />
      </div>
      <ReviewDialog openReview={openReview} reservationId={reservationId} onClickOpenReview={onClickOpenReview} />
    </div>
  );
};
