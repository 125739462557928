import React, { useEffect, useState } from 'react';
import { PhoneModel } from './PhoneModel';
import { ReserveForm } from './ReserveForm';
import { PhonePlanDialog } from './PhonePlanDialog';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { addReserve } from 'apis/supabaseApi';
import { enqueueSnackbar } from 'notistack';
import { SelectChangeEvent } from '@mui/material';
import { ComparePlanDialog } from './ComparePlanDialog';
import { AffiliateCardDialog } from './AffiliateCardDialog';
import { useNavigate } from 'react-router-dom';
import { sendMessage } from 'apis/telegramApi';
import { useQueryClient } from 'react-query';
import { PhoneModelInfo, SessionInfo } from 'types';
import dayjs from 'dayjs';

type ReserveBoxProps = {
  modelName: string;
};

export const ReserveBox = ({ modelName }: ReserveBoxProps) => {
  const client = useQueryClient();
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);
  const phoneModelList = client.getQueryData<PhoneModelInfo[]>(['phone_models', modelName]);

  const openPostCode = useDaumPostcodePopup();
  const navigate = useNavigate();

  const [phoneColor, setPhoneColor] = useState<string>('');
  const [phoneModel, setPhoneModel] = useState<string>('');
  const [phoneCapacity, setPhoneCapacity] = useState<string>('');
  const [prevTelecom, setPrevTelecom] = useState<string>('');
  const [nextTelecom, setNextTelecom] = useState<string>('SKT');
  const [phonePlan, setPhonePlan] = useState<string>('플래티넘');
  const [discountType, setDiscountType] = useState<string>('선택약정할인');
  const [paymentType, setPaymentType] = useState<string>('24개월');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [baseAddress, setBaseAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [name, setName] = useState('');
  const [birth, setBirth] = useState('');
  const [reqDetail, setReqDetail] = useState('');
  const [mvno, setMvno] = useState(false);
  const [selectGift, setSelectGift] = useState('기기 할인');
  const [affiliateCard, setAffiliateCard] = useState('');

  const [openPhonePlan, setOpenPhonePlan] = useState(false);
  const [openComparePlan, setOpenComparePlan] = useState(false);
  const [openAffiliateCard, setOpenAffiliateCard] = useState(false);
  const [agree, setAgree] = useState(false);

  const onClickPhoneColor = (phoneColorName: string) => {
    setPhoneColor(phoneColorName);
  };

  const onClickPhoneCapacity = (phoneCapacity: string) => {
    setPhoneCapacity(phoneCapacity);
  };

  const onClickPhoneModel = (phoneModel: string) => {
    setPhoneColor('');
    setPhoneCapacity('');
    setPhoneModel(phoneModel);
  };

  const onClickPrevTelecom = (prevTelecom: string) => {
    setPrevTelecom(prevTelecom);
  };

  const onClickNextTelecom = (nextTelecom: string) => {
    setNextTelecom(nextTelecom);

    if (nextTelecom === 'SKT') {
      setPhonePlan('플래티넘');
    } else if (nextTelecom === 'KT') {
      setPhonePlan('스페셜');
    } else if (nextTelecom === 'LG') {
      setPhonePlan('프리미어 슈퍼');
    }
    setAffiliateCard('');
  };

  const onClickPhonePlan = (phonePlan: string) => {
    setPhonePlan(phonePlan);
    onClickOpenPhonePlan();
  };
  const onClickDiscountType = (discountType: string) => {
    setDiscountType(discountType);
  };
  const onClickPaymentType = (paymentType: string) => {
    setPaymentType(paymentType);
  };

  const onClickOpenPhonePlan = () => {
    setOpenPhonePlan(!openPhonePlan);
  };

  const onClickOpenComparePlan = () => {
    setOpenComparePlan(!openComparePlan);
  };

  const onClickOpenAffiliateCard = () => {
    setOpenAffiliateCard(!openAffiliateCard);
  };

  const onChangePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
  };

  const onCompletePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setBaseAddress(fullAddress);
  };

  const onClickOpenPostCode = () => {
    openPostCode({ onComplete: onCompletePostCode });
  };

  const onChangeDetailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDetailAddress(event.target.value);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeBirth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBirth(event.target.value);
  };
  const onChangeReqDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReqDetail(event.target.value);
  };

  const onChangeAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  const onChangeMvno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMvno(event.target.checked);
  };

  const onChangeSelectGift = (event: SelectChangeEvent) => {
    setSelectGift(event.target.value as string);
  };

  const onClickAffiliateCard = (affiliateCard: string) => {
    setAffiliateCard(affiliateCard);
    onClickOpenAffiliateCard();
  };

  const onClickRequestReserve = async () => {
    if (!phoneColor) {
      return enqueueSnackbar('휴대폰 색상을 선택해 주세요.', { variant: 'warning' });
    }
    if (!phoneCapacity) {
      return enqueueSnackbar('휴대폰 용량을 선택해 주세요.', { variant: 'warning' });
    }
    if (!prevTelecom) {
      return enqueueSnackbar('고객님께서 현재 사용 중이신 통신사를 선택해 주세요.', { variant: 'warning' });
    }
    if (!name) {
      return enqueueSnackbar('고객님의 성함을 입력해 주세요.', { variant: 'warning' });
    }
    if (!phoneNumber) {
      return enqueueSnackbar('고객님의 휴대폰 번호를 입력해 주세요.', { variant: 'warning' });
    }
    if (!birth) {
      return enqueueSnackbar('고객님의 생년월일을 입력해 주세요.', { variant: 'warning' });
    }
    if (!baseAddress) {
      return enqueueSnackbar('배송 받으실 주소를 입력해 주세요.', { variant: 'warning' });
    }
    if (!detailAddress) {
      return enqueueSnackbar('배송 받으실 상세 주소를 입력해 주세요.', { variant: 'warning' });
    }
    if (!agree) {
      return enqueueSnackbar('약관 내용 확인 후 동의해 주세요.', { variant: 'warning' });
    }
    const result = await addReserve(
      phoneModel,
      phoneColor,
      phoneCapacity,
      prevTelecom,
      nextTelecom,
      phonePlan,
      discountType,
      paymentType,
      name,
      phoneNumber,
      birth,
      `${baseAddress} ${detailAddress}`,
      reqDetail,
      mvno,
      selectGift,
      affiliateCard,
      sessionInfo?.user.id,
    );

    if (!result) {
      sendMessage(
        `
        ${name} 고객님께서 사전예약(구매)을 신청했습니다.
        모델: ${phoneModel}
        컬러: ${phoneColor}
        용량: ${phoneCapacity}
        사용중인 통신사: ${prevTelecom}
        사용할 통신사: ${nextTelecom}
        선택한 요금제: ${phonePlan}
        할인 방법: ${discountType}
        결제 방법: ${paymentType}
        휴대폰 번호: ${phoneNumber}
        생년월일: ${birth}
        주소: ${baseAddress} ${detailAddress}
        기타 요청사항: ${reqDetail || '없음'}
        알뜰 요금제 변경 예약 여부: ${mvno || false}
        선택한 사은품: ${selectGift}
        선택한 제휴카드: ${affiliateCard || '없음'}`,
      );
      enqueueSnackbar('온라인 신청이 성공적으로 접수되었습니다. 감사합니다.', { variant: 'info' });
      navigate(`/reserve-success?modelName=${modelName}`);
    } else {
      return enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    setPhoneModel(phoneModelList ? phoneModelList[0].phone_name : '');
  }, [modelName, phoneModelList]);

  const modelInfo: PhoneModelInfo | undefined = phoneModelList?.find(
    (iphoneInfo) => iphoneInfo.phone_name === phoneModel,
  );

  return (
    <div className='my-12'>
      <div className='my-12'>
        <h1 className='text-4xl font-bold break-keep'>
          {phoneModel} {dayjs() > dayjs(modelInfo?.release_date) ? '구매' : '사전 예약'}하기
        </h1>
        <div className='text-neutral-600 my-4'>
          <p>{dayjs(modelInfo?.reservation_date).format('YY년 MM월 DD일')} 사전 주문</p>
          <p>{dayjs(modelInfo?.release_date).format('YY년 MM월 DD일')} 출시</p>
        </div>
      </div>
      <PhoneModel
        phoneModelList={phoneModelList || []}
        phoneModel={phoneModel}
        phoneColor={phoneColor}
        phoneCapacity={phoneCapacity}
        onClickPhoneColor={onClickPhoneColor}
        onClickPhoneModel={onClickPhoneModel}
        onClickPhoneCapacity={onClickPhoneCapacity}
      />
      <ReserveForm
        phoneModelList={phoneModelList || []}
        phoneModel={phoneModel}
        phoneCapacity={phoneCapacity}
        prevTelecom={prevTelecom}
        nextTelecom={nextTelecom}
        phonePlan={phonePlan}
        discountType={discountType}
        paymentType={paymentType}
        phoneNumber={phoneNumber}
        baseAddress={baseAddress}
        detailAddress={detailAddress}
        name={name}
        birth={birth}
        reqDetail={reqDetail}
        agree={agree}
        mvno={mvno}
        selectGift={selectGift}
        affiliateCard={affiliateCard}
        onClickPrevTelecom={onClickPrevTelecom}
        onClickNextTelecom={onClickNextTelecom}
        onClickDiscountType={onClickDiscountType}
        onClickPaymentType={onClickPaymentType}
        onClickOpenPhonePlan={onClickOpenPhonePlan}
        onChangePhoneNumber={onChangePhoneNumber}
        onClickOpenPostCode={onClickOpenPostCode}
        onChangeDetailAddress={onChangeDetailAddress}
        onChangeBirth={onChangeBirth}
        onChangeName={onChangeName}
        onChangeReqDetail={onChangeReqDetail}
        onClickRequestReserve={onClickRequestReserve}
        onChangeAgree={onChangeAgree}
        onChangeMvno={onChangeMvno}
        onChangeSelectGift={onChangeSelectGift}
        onClickOpenComparePlan={onClickOpenComparePlan}
        onClickOpenAffiliateCard={onClickOpenAffiliateCard}
      />
      <PhonePlanDialog
        nextTelecom={nextTelecom}
        openPhonePlan={openPhonePlan}
        onClickOpenPhonePlan={onClickOpenPhonePlan}
        onClickPhonePlan={onClickPhonePlan}
      />
      <ComparePlanDialog openComparePlan={openComparePlan} onClickOpenComparePlan={onClickOpenComparePlan} />
      <AffiliateCardDialog
        openAffiliateCard={openAffiliateCard}
        nextTelecom={nextTelecom}
        onClickAffiliateCard={onClickAffiliateCard}
        onClickOpenAffiliateCard={onClickOpenAffiliateCard}
      />
    </div>
  );
};
