import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MainPage } from 'pages/MainPage';
import { ReservePage } from 'pages/ReservePage';
import { NotFoundPage } from 'pages/error/NotFoundPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ReserveSuccessPage } from 'pages/ReserveSuccessPage';
import { RouteChangeTracker } from 'common/googleAnalytics';
import { HelmetProvider } from 'react-helmet-async';
import { SignInPage } from 'pages/SignInPage';
import { useQuery } from 'react-query';
import { getSession } from 'apis/supabaseApi';
import { Backdrop, CircularProgress } from '@mui/material';
import { ChangePwdPage } from 'pages/ChangePwdPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { AgreementPage } from 'pages/AgreementPage';
import { MyPage } from 'pages/MyPage';
import { ReservationListPage } from 'pages/mypage/ReservationListPage';
import { ReviewListPage } from 'pages/mypage/ReviewListPage';

const muiTheme = createTheme({
  // palette: {
  //   primary: { main: 'rgb(245 158 11);' },
  // },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  const { isLoading } = useQuery(['session'], getSession);

  RouteChangeTracker();

  if (isLoading)
    return (
      <Backdrop sx={{ bgcolor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );

  return (
    <ThemeProvider theme={muiTheme}>
      <HelmetProvider>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/sign-in' element={<SignInPage />} />
            <Route path='/reserve' element={<ReservePage />} />
            <Route path='/reserve-success' element={<ReserveSuccessPage />} />
            <Route path='/change-pwd' element={<ChangePwdPage />} />
            <Route path='/privacy' element={<PrivacyPage />} />
            <Route path='/agreement' element={<AgreementPage />} />
            <Route path='/mypage' element={<MyPage />} />
            <Route path='/mypage/reservation-list' element={<ReservationListPage />} />
            <Route path='/mypage/review-list' element={<ReviewListPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </SnackbarProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
