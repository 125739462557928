import { Button, Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material';
import { numberWithCommas } from 'common/numberWithCommas';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

type affiliateCardInfo = {
  telecom: string;
  cards: {
    cardName: string;
    cardImgUrl?: string;
    cardDetailUrl: string;
    promotions: { title: string; discount: number }[];
  }[];
};

export const affiliateCardList: affiliateCardInfo[] = [
  {
    telecom: 'SKT',
    cards: [
      {
        cardName: '[우리카드] T라이트 NU우리카드',
        cardImgUrl: 'NU.png',
        cardDetailUrl: 'https://m.wooricard.com/dcmw/yh1/crd/crd01/M1CRD101S02.do?cdPrdCd=102272',
        promotions: [
          { title: '40만원 이상(프로모션)', discount: 24000 },
          { title: '80만원 이상(프로모션)', discount: 25000 },
          { title: '120만원 이상(프로모션)', discount: 26000 },
        ],
      },
      {
        cardName: '[삼성카드] T라이트 삼성카드(삼성단말전용)',
        cardImgUrl: 'SAM.png',
        cardDetailUrl: 'https://www.samsungcard.com/home/card/cardinfo/PGHPPCCCardCardinfoDetails001?code=AAP1613',
        promotions: [
          { title: '전월실적 40만원 이상(프로모션)', discount: 22000 },
          { title: '전월실적 80만원 이상(프로모션)', discount: 23000 },
          { title: '전월실적 120만원 이상(프로모션)', discount: 24000 },
        ],
      },
      {
        cardName: '[신한카드] T라이트 신한카드',
        cardImgUrl: 't-lite.png',
        cardDetailUrl: 'https://www.shinhancard.com/pconts/html/card/apply/credit/1196330_2207.html',
        promotions: [
          { title: '30만원 이상', discount: 10000 },
          { title: '70만원 이상', discount: 15000 },
          { title: '30만원이상(89요금제이상 프로모션) Master 카드(해외겸용)만 적용', discount: 25000 },
        ],
      },
    ],
  },
  {
    telecom: 'KT',
    cards: [
      {
        cardName: '[국민카드] KT Super 할부 KB국민카드',
        cardImgUrl: 'kb.png',
        cardDetailUrl: 'https://m.kbcard.com/CRD/DVIEW/MCAMCXHIACRC0002?mainCC=b&allianceCode=04244',
        promotions: [{ title: '30만원 이상(프로모션)', discount: 20000 }],
      },
      {
        cardName: '[우리카드] KT NU카드',
        cardImgUrl: 'KTNU.png',
        cardDetailUrl: 'https://m.wooricard.com/dcmw/yh1/crd/crd01/M1CRD101S02.do?cdPrdCd=102273',
        promotions: [
          { title: '40만원 이상(프로모션)', discount: 22000 },
          { title: '80만원 이상(프로모션)', discount: 24000 },
          { title: '120만원 이상(프로모션)', discount: 26000 },
        ],
      },
      {
        cardName: '[삼성카드] KT 삼성카드',
        cardImgUrl: 'SAMKT.png',
        cardDetailUrl:
          'https://www.samsungcard.com/home/card/cardinfo/PGHPPCCCardCardinfoDetails001?code=AAP1665&click=hubca_partnerca_AAP1665',
        promotions: [
          { title: '30만원 이상(프로모션) 기존고객 브랜드 교체 제외', discount: 19000 },
          { title: '70만원 이상(프로모션) 기존고객 브랜드 교체 제외', discount: 21000 },
        ],
      },
    ],
  },
  {
    telecom: 'LG',
    cards: [
      {
        cardName: '[삼성카드] LG U+ 삼성카드',
        cardImgUrl: 'SAMLG.png',
        cardDetailUrl:
          'https://www.samsungcard.com/home/card/cardinfo/PGHPPCCCardCardinfoDetails001?code=AAP1663&alncmpC=QKCWHP&affcode=QKCWHP&webViewFirstPage=true',
        promotions: [
          { title: '전월실적 30만원 이상[프로모션]', discount: 18000 },
          { title: '전월실적 70만원 이상[프로모션]', discount: 20000 },
          { title: '전월실적 120만원 이상[프로모션]', discount: 20000 },
        ],
      },
      {
        cardName: '[우리카드] LG U+ NU우리카드',
        cardImgUrl: 'NULG.png',
        cardDetailUrl: 'https://pc.wooricard.com/dcpc/yh1/crd/crd01/H1CRD101S02.do?cdPrdCd=102274',
        promotions: [
          { title: '40만원 이상(프로모션)', discount: 19000 },
          { title: '80만원 이상(프로모션)', discount: 24000 },
          { title: '120만원 이상(프로모션)', discount: 26000 },
        ],
      },
      {
        cardName: '[국민카드]LG U+ KB 라이트할부II 카드',
        cardImgUrl: 'lgkb.png',
        cardDetailUrl: 'https://card.kbcard.com/CRD/DVIEW/HCAMCXPRICAC0076?mainCC=a&cooperationcode=09329',
        promotions: [{ title: '전월실적 30만원 이상(프로모션)', discount: 20000 }],
      },
    ],
  },
];

type AffiliateCardDialogProps = {
  openAffiliateCard: boolean;
  nextTelecom: string;
  onClickAffiliateCard(affiliateCard: string): void;
  onClickOpenAffiliateCard(): void;
};

export const AffiliateCardDialog = ({
  openAffiliateCard,
  nextTelecom,
  onClickAffiliateCard,
  onClickOpenAffiliateCard,
}: AffiliateCardDialogProps) => {
  const telecomImgUrl = nextTelecom === 'SKT' ? 'skt.png' : nextTelecom === 'KT' ? 'kt.png' : 'lg.png';
  const selectTelecomAffiliateCardList = affiliateCardList.find((x) => x.telecom === nextTelecom);
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog open={openAffiliateCard} fullWidth fullScreen={fullscreen} maxWidth='xl'>
      <div className='p-4'>
        <div className='flex items-center'>
          <div className='flex items-center text-2xl font-bold'>
            <img src={telecomImgUrl} alt={nextTelecom} /> <span className='ml-2'>제휴카드</span>
          </div>
          <div className='cursor-pointer ml-auto' onClick={() => onClickOpenAffiliateCard()}>
            <CloseIcon fontSize='large' />
          </div>
        </div>
        <DialogContent className='!px-0'>
          <Grid container spacing={2} direction={{ xs: 'column', lg: 'row' }}>
            {selectTelecomAffiliateCardList?.cards.map((cardInfo) => (
              <Grid item key={cardInfo.cardName} xs={4}>
                <div className={`px-8 py-4 max-sm:px-2 rounded-lg border text-center`}>
                  <div className='my-4'>
                    <img src={cardInfo.cardImgUrl} alt={cardInfo.cardName} className='w-64 mx-auto' />
                  </div>
                  <div className='text-lg font-bold my-4 break-keep'>{cardInfo.cardName}</div>
                  {cardInfo.promotions.map((promotionInfo) => (
                    <div
                      key={promotionInfo.title}
                      className='border-2 border-neutral-200 rounded-md px-2 py-4 hover:border-blue-500 hover:bg-neutral-100 my-2 cursor-pointer'
                      onClick={() => onClickAffiliateCard(cardInfo.cardName + '|' + promotionInfo.title)}
                    >
                      <p className='font-bold break-keep'>{promotionInfo.title}</p>
                      <div className='text-sm text-stone-600 flex flex-wrap justify-center'>
                        <p className='mr-1'>
                          매월{' '}
                          <span className='font-bold text-red-500'>{numberWithCommas(promotionInfo.discount)}원</span> X
                          24개월 =
                        </p>
                        <p>
                          <span className='font-bold text-red-500'>
                            {numberWithCommas(promotionInfo.discount * 24)}원
                          </span>{' '}
                          할인
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className='my-4'>
                    <a href={cardInfo.cardDetailUrl} target='_blank' rel='noreferrer'>
                      <Button
                        fullWidth
                        variant='contained'
                        className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
                      >
                        자세히 보기
                      </Button>
                    </a>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <div className='mb-2'>
          <Button
            fullWidth
            variant='contained'
            className='!bg-black !text-white'
            onClick={() => onClickAffiliateCard('')}
          >
            제휴카드 선택 안함
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
