const chatIds = ['-4059829118'];

export const sendMessage = (message: string) => {
  const botToken = '1307484758:AAFqEjtDXK9TcntbVQjsaACtFb0OlVEjLwY';

  const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

  chatIds.forEach((chatId) => {
    // HTTP POST 요청을 사용하여 메시지 보내기
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Message sent:', data);
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  });
};
