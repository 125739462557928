import { CircularProgress, Rating } from '@mui/material';
import dayjs from 'dayjs';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReservationInfo, ReviewInfo } from 'types';

type ReviewListProps = {
  reservationDatas: ReservationInfo[];
  reviewDatas: ReviewInfo[];
  isLoadingReservation: boolean;
  isLoadingReview: boolean;
};

export const ReviewList = ({
  reservationDatas,
  reviewDatas,
  isLoadingReservation,
  isLoadingReview,
}: ReviewListProps) => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'created_at',
      label: '작성 날짜',
      options: {
        customBodyRender: (value: string) => {
          return <div>{String(dayjs(value).format('YYYY.MM.DD'))}</div>;
        },
        sort: false,
      },
    },
    {
      name: 'img_urls',
      label: '리뷰 사진',
      options: {
        customBodyRender: (img_urls: string[]) => {
          return (
            <div className='flex flex-wrap'>
              {img_urls?.map((img_url) => (
                <img src={img_url || ''} alt='' key={img_url} className='h-12 w-12 m-1' />
              ))}
            </div>
          );
        },
        sort: false,
      },
    },
    {
      name: 'content',
      label: '리뷰 내용',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const reservationInfo = reservationDatas.find((x) => x.id === reviewDatas![dataIndex].reservation_id);
          return (
            <div>
              <div className='flex items-center'>
                <div>
                  <p className='font-bold my-2'>
                    {reservationInfo?.phone_model} {reservationInfo?.phone_capacity} {reservationInfo?.phone_color}
                  </p>
                  <p>{reviewDatas![dataIndex].content}</p>
                </div>
                <div className='ml-auto'>
                  <Rating value={reviewDatas![dataIndex].rate} readOnly size='small' />
                </div>
              </div>
            </div>
          );
        },
        sort: false,
      },
    },
  ];

  return (
    <div className='mb-16'>
      <div className='flex items-end font-bold text-xl border-b-4 border-black pb-2'>
        <p>리뷰 작성 내역</p>
        <Link to={'/mypage/review-list'} className='ml-auto'>
          <p className='text-sm text-stone-800'>더 보기 &gt;</p>
        </Link>
      </div>
      <div className='my-4'>
        <MUIDataTable
          title={''}
          columns={columns}
          data={(reviewDatas as ReviewInfo[]) || []}
          components={{
            TableToolbar(props) {
              return null;
            },
          }}
          options={{
            responsive: 'simple',
            selectableRows: 'none',
            textLabels: {
              body: {
                noMatch:
                  isLoadingReview && isLoadingReservation ? (
                    <CircularProgress size={26} color='inherit' />
                  ) : (
                    '리뷰 작성 내역이 없습니다.'
                  ),
              },
              pagination: {
                next: '다음 페이지',
                previous: '이전 페이지',
                rowsPerPage: '페이지 단위: ',
                displayRows: '중에',
              },
              toolbar: {
                search: '검색',
                downloadCsv: 'CSV로 다운로드',
                print: '인쇄',
                viewColumns: '컬럼 옵션',
                filterTable: '필터',
              },
            },
          }}
        />
      </div>
    </div>
  );
};
