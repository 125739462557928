import React from 'react';
import ChannelService from 'ChannelService';
type TemplateProps = {
  header?: JSX.Element;
  children: JSX.Element;
  footer?: JSX.Element;
};

export const Template = ({ header, children, footer }: TemplateProps) => {
  ChannelService.loadScript();

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_C_T_P_K || '',
  });

  return (
    <>
      {header}
      <main>{children}</main>
      {footer}
    </>
  );
};
